<template>
  <div>
    <v-row justify="center" no-gutters>
      <h1 v-bind:class="mobile ? 'mainTitleMobile' : 'mainTitle paddingTitle'">{{ $t('mainTitle') }}</h1>
    </v-row>
    <v-row justify="center" no-gutters v-bind:class="mobile ? 'firstSectionMobile' : 'firstSection'">
      <v-col v-bind:cols="mobile ? 10 : 5" class="leftSection">
        <p class="textSection" v-html="$t('textLeft')"></p>
      </v-col>
      <v-col cols="1" v-if="!mobile"></v-col>
      <v-col v-bind:cols="mobile ? 10 : 5" class="rightSection">
        <p class="textSection" v-html="$t('textRight')"></p>
      </v-col>
      <v-col cols="1" v-if="!mobile"></v-col>
    </v-row>
    <v-row justify="center" class="secondSection" no-gutters v-if="!mobile">
      <v-col cols="5" class="textLeft">
        <h3 class="subTitleSection" v-html="$t('subtitle1')"></h3>
        <div v-html="$t('text1')"></div>
        <div class="imgLeft">
          <v-img
              :src="require('@/assets/vitaeImg/section29.png')"
              contain
              height="200"
          />
        </div>
        <br> <br> <br>
        <h3 class="subTitleSection" v-html="$t('subtitle3')"></h3>
        <div v-html="$t('text3')"></div>
        <div class="imgLeft">
          <v-img
              :src="require('@/assets/vitaeImg/section31.png')"
              contain
              height="200"
          />
        </div>
        <br><br><br><br><br>
        <h3 class="subTitleSection" v-html="$t('subtitle5')"></h3>
        <div v-html="$t('text5')"></div>
        <div class="imgLeft">
          <v-img
              :src="require('@/assets/vitaeImg/section16.png')"
              contain
              height="200"
          />
        </div>
      </v-col>
      <v-col cols="2">
        <v-img
            class="imgFirstSection"
            :src="require('@/assets/picto/separator10.svg')"
            contain
            height="2100"
        />
      </v-col>
      <v-col cols="5" class="textRight">
        <div class="imgRight">
          <v-img
              :src="require('@/assets/vitaeImg/section28.png')"
              contain
              height="200"
          />
        </div>
        <br>
        <br>
        <br>
        <br>
        <h3 class="subTitleSection" v-html="$t('subtitle2')"></h3>
        <div v-html="$t('text2')"></div>
        <div class="imgRight" style="margin-top: 220px;">
          <v-img
              :src="require('@/assets/vitaeImg/section30.png')"
              contain
              height="200"
          />
        </div>
        <br> <br> <br><br> <br>
        <h3 class="subTitleSection" v-html="$t('subtitle4')"></h3>
        <div v-html="$t('text4')"></div>
        <div class="imgRight" style="margin-top: 100px;">
          <v-img
              :src="require('@/assets/vitaeImg/section32.png')"
              contain
              height="200"
          />
        </div>
        <br> <br> <br><br> <br>
        <h3 class="subTitleSection" v-html="$t('subtitle6')"></h3>
        <div v-html="$t('text6')"></div>
      </v-col>
    </v-row>
    <v-expansion-panels v-if="mobile" flat class="sectionMobile">
      <v-expansion-panel>
        <v-expansion-panel-header expand-icon="">
          <h3 class="titleSectionMobile"> {{ $t('subtitle1')}}</h3>
          <template v-slot:actions>
            <v-icon color="#57939E" x-large>
              $expand
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <p class="textSectionMobile" v-html="$t('text1')"></p>
          <br>
          <v-img
              :src="require('@/assets/vitaeImg/section28.png')"
              contain
              height="130"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header expand-icon="">
          <h3 class="titleSectionMobile"> {{ $t('subtitle2')}}</h3>
          <template v-slot:actions>
            <v-icon color="#57939E" x-large>
              $expand
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <p class="textSectionMobile" v-html="$t('text2')"></p>
          <br>
          <v-img
              :src="require('@/assets/vitaeImg/section23.png')"
              contain
              height="130"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header expand-icon="">
          <h3 class="titleSectionMobile"> {{ $t('subtitle3')}}</h3>
          <template v-slot:actions>
            <v-icon color="#57939E" x-large>
              $expand
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <p class="textSectionMobile" v-html="$t('text3')"></p>
          <br>
          <v-img
              :src="require('@/assets/vitaeImg/section24.png')"
              contain
              height="130"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header expand-icon="">
          <h3 class="titleSectionMobile"> {{ $t('subtitle4')}}</h3>
          <template v-slot:actions>
            <v-icon color="#57939E" x-large>
              $expand
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <p class="textSectionMobile" v-html="$t('text4')"></p>
          <br>
          <v-img
              :src="require('@/assets/vitaeImg/section31.png')"
              contain
              height="130"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header expand-icon="">
          <h3 class="titleSectionMobile"> {{ $t('subtitle5')}}</h3>
          <template v-slot:actions>
            <v-icon color="#57939E" x-large>
              $expand
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <p class="textSectionMobile" v-html="$t('text5')"></p>
          <br>
          <v-img
              :src="require('@/assets/vitaeImg/section26.png')"
              contain
              height="130"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header expand-icon="">
          <h3 class="titleSectionMobile"> {{ $t('subtitle6')}}</h3>
          <template v-slot:actions>
            <v-icon color="#57939E" x-large>
              $expand
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <p class="textSectionMobile" v-html="$t('text6')"></p>
          <br>
          <v-img
              :src="require('@/assets/vitaeImg/section16.png')"
              contain
              height="130"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
  export default {
    name: 'PhysicalActivitiesEnterprise',
    props: ['mobile'],
    data: () => ({
    }),
  }
</script>

<i18n>
{
  "en": {
    "mainTitle": "The adapted physical activities offered by the company",
    "textLeft": "If the employee benefits directly from the positive effects of sport, particularly in their daily professional life (better stress management, quality of sleep, dynamism, etc.), the employer also has everything to gain. <br> By promoting the well-being of its employees, the employer increases their motivation and their efficiency at work.",
    "textRight": "The association works with a large number of professionals and can adapt to all desires. <br> <strong> Do not hesitate to contact us </strong> to build your business sport project.",
    "subtitle1": "Fit Active activity",
    "subtitle2": "Pilates",
    "subtitle3": "Yoga",
    "subtitle4": "Nordic walking",
    "subtitle5": "Sophrology workshop",
    "subtitle6": "Nutrition workshop",
    "text1": "<br> This activity, which is inspired by Pilatus and fitness, includes several important aspects: <br> <strong> - Posture, balance and coordination exercises <br> - Muscle strengthening of the lower and upper limbs <br> - Sheathing on the ground or in imbalance <br> - Collective play <br> - Stretching / Relaxation </strong>",
    "text2": "<br> <br> The pillates method is a gentle gymnastics that combines deep breathing with physical exercises ... The pillates is a method of physical training inspired by yoga, dance and gymnastics. It is practiced on the floor, on a mat, or with the help of apparatus.",
    "text3": "<br> Yoga is the practice of a set of postures and breathing exercises aimed at providing physical and mental well-being. This ancient art of living as explained in the texts is revealed as an initiatory path that transcends physical discipline. <br> <br> With a regular practice we can correct postural errors developed throughout our life which greatly alleviates chronic back problems. A 2008 study by Temple University found that practicing Iyengar yoga improves balance in women over 65.",
    "text4": "<br> Nordic walking is a dynamic walking practice in the heart of nature. <br> It is the ideal combination of endurance training accessible to all, walking, and muscle building of the whole body. The principle is simple: it is to accentuate the natural movement of the arms while walking and to propel the body forward with the help of two poles which allow you to go faster and walk longer. <br> The whole body kicks in, strengthening 80% of the muscle chains.",
    "text5": "<br> The difference between our aspirations and the successes we achieve builds self-esteem. Discussing, sharing, creating links with others, are moments that maintain good self-esteem. Positive self-esteem is a defense mechanism. A person who has fairly good self-esteem is more likely to face life's challenges. <br> <strong> The objective of this workshop is to strengthen a positive image of oneself in order to adopt favorable attitudes. </strong>",
    "text6": "<br> Nutrition plays a very important role in health. <br> Depending on our habits, the body changes, as do nutritional needs. <br> It is important to ensure that we eat better and be active every day . The aim of the « good on your plate, at ease in your sneakers » workshop is aimed at raising awareness, informing and educating active people about a diet adapted to nutritional needs and a daily adapted physical activity in order to protect against certain diseases (diseases cardiovascular diseases, cancer, etc.), to limit fatigue problems, to defend oneself against infections while continuing to live according to one's wishes."
  },
  "fr": {
    "mainTitle": "Les activités physiques adaptées proposées en entreprise",
    "textLeft": "Si le salarié bénéficie directement des effets positifs du sport, notamment dans son quotidien professionnel (meilleur gestion du stress, qualité du sommeil, dynamisme…), l’employeur a lui aussi tout à y gagner. <br> En favorisant le bien-être de ses salariés, l’employeur accroit leur motivation et leur efficacité au travail.",
    "textRight": "L’association travaille avec un grand nombre de professionnel et peut s’adapter à toutes les envies. <br> <strong> N’hésitez pas à nous contacter </strong> pour construire votre projet sport entreprise.",
    "subtitle1": "L’activité Fit Active",
    "subtitle2": "Pilates",
    "subtitle3": "Yoga",
    "subtitle4": "Marche nordique",
    "subtitle5": "Atelier sophrologie",
    "subtitle6": "Atelier nutrition",
    "text1": "<br>Cette activité qui s’inspire du Pilate et du fitness comprend plusieurs aspects importants : <br> <strong>- Exercices de posture, d’équilibre et de coordination <br> - Renforcement musculaire des membres inférieurs et supérieurs <br> - Gainage au sol ou en déséquilibre <br> - Jeu collectif <br> - Stretching/Relaxation </strong>",
    "text2": "<br><br>La méthode pillates est une gymnastique douce qui allie une respiration profonde avec des exercices physiques... Le pillates est une méthode d’entraînement physique qui s’inspire du yoga, de la danse et de la gymnastique. Elle se pratique au sol, sur un tapis, ou à l’aide d’appareils.",
    "text3": "<br>Le yoga est la pratique d’un ensemble de postures et d‘exercices de respiration qui vise à apporter un bien être physique et mental. Cet ancien art de vivre tel qu’il est expliqué dans les textes se révèle comme un chemin initiatique qui transcende la discipline physique. <br><br> Avec une pratique régulière on peut corriger des erreurs de posture développées tout au long de notre vie ce qui atténue grandement les problèmes de dos chroniques. Une étude réalisée par l’Université du temple en 2008 a montré que la pratique du yoga Iyengar améliore l’équilibre chez les femmes de plus de 65 ans.",
    "text4": "<br>La marche nordique est une pratique de marche dynamique en pleine nature. <br>Elle allie de manière idéale un travail d’endurance accessible à tous, la marche, et un renforcement musculaire de l’ensemble du corps. Le principe est simple : il s’agit d’accentuer le mouvement naturel des bras pendant la marche et de propulser le corps vers l’avant à l’aide de deux bâtons qui permettent d’aller plus vite et de marcher plus longtemps. <br> L’ensemble du corps entre en action, renforçant ainsi 80% des chaînes musculaires.",
    "text5": "<br>La différence entre nos aspirations et les succès que nous parvenons à accomplir construit l’estime de soi. Echanger, partager, créer du lien avec les autres, sont des moments qui entretiennent une bonne estime de soi. L’estime de soi positive est un mécanisme de défense. Une personne qui a plutôt une bonne estime d’elle-même a plus de facilité à affronter les défis de la vie. <br> <strong>Cet atelier a pour objectif de renforcer une image positive de soi-même afin d’adopter des attitudes favorables. </strong>",
    "text6": "<br>La nutrition joue un rôle très important sur la santé.<br> En fonction de nos habitudes, l’organisme change, les besoins nutritionnels aussi.<br> Il est important de veiller à manger mieux et à bouger tous les jours. L’atelier « bien dans son assiette, à l’aise dans ses baskets » a pour vocation de sensibiliser, informer et éduquer les personnes actives à une alimentation adaptée aux besoins nutritionnels et une activité physique adaptée quotidienne afin de protéger contre certaines maladies (maladies cardiovasculaires, cancers, etc.), à limiter les problèmes de fatigue, à se défendre contre les infections tout en continuant à vivre selon ses désirs."
  }
}
</i18n>

<style scoped>

@media screen and (max-width: 1500px) {
  .paddingTitle {
    text-align: center;
    padding-left: 400px;
    padding-right: 400px;
  }
}
@media screen and (min-width: 1500px) {
  .paddingTitle {
    text-align: center;
    padding-left: 600px;
    padding-right: 600px;
  }
}

.textSectionMobile {
  font-size: 14px;
  text-align: left;
  color: #808080;
}
.titleSectionMobile {
  font-size: 16px;
  margin-right: 100px;
  font-family: Comfortaa, sans-serif;
  color: #57939E;
}

.firstSection {
  padding-right: 200px;
  padding-left: 200px;
}

.firstSectionMobile {
  font-size: 14px;
  padding-bottom: 20px;
}

.leftSection {
  padding-left: 200px;
  padding-right: 120px;
}

.rightSection {
  padding-right: 250px;
}

.textSection {
  color: #1579A7;
}

.secondSection {
  padding-right: 200px;
  padding-left: 200px;
  padding-top: 80px;
}

.subTitleSection {
  padding-top: 10px;
  font-family: Comfortaa, sans-serif;
  color: #1579A7;
}

.textLeft {
  padding-left: 200px;
  text-align: right;
  color: #808080;
}

.textRight {
  padding-right: 200px;
  color: #808080;
}

.imgRight {
  margin-top: 40px;
  margin-right: 250px;
}

.imgLeft {
  margin-top: 120px;
  margin-left: 250px;
}

</style>
