<template>
  <div>
    <PhysicalActivitiesEnterpriseComp v-bind:mobile="mobile"/>
    <RedirectEnterpriseComp v-bind:mobile="mobile"/>
  </div>
</template>

<script>
import PhysicalActivitiesEnterpriseComp from '@/components/desktop/Enterprise/PhysicalActivitiesEnterpriseComp'
import RedirectEnterpriseComp from '@/components/desktop/Enterprise/RedirectEnterpriseComp'

  export default {
    name: 'PhysicalActivities',
    props: ['mobile'],
    components: {
      PhysicalActivitiesEnterpriseComp,
      RedirectEnterpriseComp
    },
  }
</script>
